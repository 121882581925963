(function ($) {

    "use strict"

    $('#menuFooterToggle').on('click', function () {
        $('#menuFooter').toggleClass('show');
    });

    $('.navbar-main-collapse').on('show.bs.collapse', function () {
        $('body').addClass('open-main-navbar');
        try {
            $.fn.fullpage.setAutoScrolling(false);
        } catch (err) {
        }

    });
    $('.navbar-main-collapse').on('hide.bs.collapse', function () {
        $('body').removeClass('open-main-navbar');
        try {
            $.fn.fullpage.setAutoScrolling(true);
        } catch (err) {
        }

    });

    $(window).scroll(function (e) {
        var $el = $('#sub-navbar');
        var isPositionFixed = ($el.css('position') == 'fixed');
        if ($(this).scrollTop() > 50 && !isPositionFixed) {
            $el.css({'position': 'fixed', 'top': '0px'});
        }
        if ($(this).scrollTop() < 50 && isPositionFixed) {
            $el.css({'position': 'absolute', 'top': '50px'});
        }
    });

    $(document).ready(function () {
        $('#owl-slider-home').owlCarousel({
            items: 1,
            dots: true,
            autoplay: true,
            autoplayTimeout: 10000,
            autoplayHoverPause: true
        });

    });


    // $(document).ready(function() {
    //   $('#HomeSlider').slick({
    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 1,
    //     arrows: false,
    //     autoplay: true,
    //     autoplaySpeed: 5000,
    //     centerMode: true,
    //     centerPadding: '0',
    //     mobileFirst: true,
    //     adaptiveHeight: true,
    //     speed: 500
    //   });
    // });

    $(document).ready(function () {
        if ($('#ServiziContainer').length > 0) {
            $('#ServiziContainer').fullpage({
                navigation: true,
                navigationPosition: 'right',
                showActiveTooltip: false,
                slidesNavigation: true,
                slidesNavPosition: 'bottom',

                css3: true,
                scrollingSpeed: 700,
                autoScrolling: true,
                fitToSection: false,
                fitToSectionDelay: 1000,
                scrollBar: true,
                easing: 'easeInOutCubic',
                easingcss3: 'ease',
                loopBottom: false,
                loopTop: false,
                loopHorizontal: true,
                continuousVertical: false,
                normalScrollElements: '.section-footer',
                scrollOverflow: false,
                touchSensitivity: 15,
                normalScrollElementTouchThreshold: 5,

                keyboardScrolling: true,
                animateAnchor: true,
                recordHistory: true,

                controlArrows: true,
                verticalCentered: false,
                resize: true,
                sectionsColor: '#fff',
                //fixedElements: '#layout-header',
                responsiveWidth: 0,
                responsiveHeight: 0,

                sectionSelector: '.section',
                slideSelector: '.slide',

                afterResize: function () {
                    if ($(window).width() <= 768) {
                        $.fn.fullpage.setAutoScrolling(false);
                        $('.section').addClass('fp-auto-height');
                    } else {
                        $.fn.fullpage.setAutoScrolling(true);
                        $('div.section').removeClass('fp-auto-height');
                    }
                },

                afterRender: function () {
                    if ($(window).width() <= 768) {
                        $.fn.fullpage.setAutoScrolling(false);
                        $('.section').addClass('fp-auto-height');
                    }
                    $('#fp-nav ul li a span').css('background', '#fff');
                }
            });
        }

        if ($('#ProdottiContainer').length > 0) {
            $('#ProdottiContainer').fullpage({
                navigation: true,
                navigationPosition: 'right',
                showActiveTooltip: false,
                slidesNavigation: true,
                slidesNavPosition: 'bottom',

                css3: true,
                scrollingSpeed: 700,
                autoScrolling: true,
                fitToSection: false,
                fitToSectionDelay: 1000,
                scrollBar: true,
                easing: 'easeInOutCubic',
                easingcss3: 'ease',
                loopBottom: false,
                loopTop: false,
                loopHorizontal: true,
                continuousVertical: false,
                normalScrollElements: '.section-footer',
                scrollOverflow: false,
                touchSensitivity: 15,
                normalScrollElementTouchThreshold: 5,

                keyboardScrolling: true,
                animateAnchor: true,
                recordHistory: true,

                controlArrows: true,
                verticalCentered: true,
                resize: false,
                responsiveWidth: 0,
                responsiveHeight: 0,

                sectionSelector: '.section',
                slideSelector: '.slide',
                paddingTop: '80px',

                afterResize: function () {
                    if ($(window).width() <= 768) {
                        $.fn.fullpage.setAutoScrolling(false);
                        $('.section').addClass('fp-auto-height');
                    } else {
                        $.fn.fullpage.setAutoScrolling(true);
                        $('div.section').removeClass('fp-auto-height');
                    }
                },
                afterRender: function () {
                    if ($(window).width() <= 768) {
                        $.fn.fullpage.setAutoScrolling(false);
                        $('.section').addClass('fp-auto-height');
                    }
                    $('#fp-nav ul li a span').css('background', '#181b21');
                }
            });
        }

    });

    // $(window).resize(function () {
    //     if ($(window).width() <= 768) {
    //         $('.section').addClass('fp-auto-height');
    //     } else {
    //         $('div.section').removeClass('fp-auto-height');
    //     }
    // });

    $('.sub-navbar-logo-li').not('.active').hover(function () {
            $('.sub-navbar-logo').attr('src', '/themes/italdron/assets/images/loghi/logoDrake-vect-black.svg');
        }, function () {
            $('.sub-navbar-logo').attr('src', '/themes/italdron/assets/images/loghi/logoDrake-vect-white.svg');
        }
    );

    $('[data-toggle="tooltip"]').tooltip();

}(jQuery));

function showMore(e, el, link) {
    e.preventDefault();
    $(el).toggleClass('show');
    $(link).toggleClass('show');
}

